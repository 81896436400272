import { CombatResult } from "../../combat";
import { GameChatChannel } from "../../enum/game";
import { ApiCountryExtraData } from "../../types/apigame";

export enum ActionType {
    None = 0,
    CreateGame,
    JoinGame,
    AssignSeat,
    Start,
    AssignCountry, // 5
    AwardUnits,
    PlaceUnits,
    Attack,
    Capture,
    AwardCards, // 10
    GameOver,
    TransferUnits,
    TurnInCards,
    EliminatePlayer,
    InvitedGame, // 15
    CancelGame,
    Surrender,
    Fogged,
    FoggedAttack,
    FoggedTransferUnits, // 20
    AssignAssassinTarget,
    AddBot,
    CatanRoll,
    Chat,
    BeginTurn, // 25
    Nudge,
    PlaceCapital,
    DisappearCountry,
    BlizzardCountry,
    HiddenAwardCards, // 30
    Hidden,
    AcceptInvite,
    InviteEmail,
};

export const REPLAY_IGNORE_ACTION_TYPES = [
    ActionType.Fogged,
    ActionType.Hidden,
];

export type Action =
    NoneAction |
    CreateGameAction |
    JoinGameAction |
    AssignSeatAction |
    StartAction |
    AssignCountryAction |
    AwardUnitsAction |
    PlaceUnitsAction |
    AttackAction |
    CaptureAction |
    AwardCardsAction |
    GameOverAction |
    TransferUnitsAction |
    TurnInCardsAction |
    EliminatePlayerAction |
    InvitedGameAction |
    CancelGameAction |
    SurrenderAction |
    FoggedAction |
    FoggedAttackAction |
    FoggedTransferUnitsAction |
    AssignAssassinTargetAction |
    AddBotAction |
    CatanRollAction |
    ChatAction |
    BeginTurnAction |
    NudgeAction |
    PlaceCapitalAction |
    DisappearCountryAction |
    BlizzardCountryAction |
    HiddenAwardCardsAction |
    HiddenAction;

export type ChatActions = ChatAction | NudgeAction;

export type ActionData =
    CreateGameActionData |
    AssignSeatActionData |
    AssignCountryActionData |
    AwardUnitsActionData |
    PlaceUnitsActionData |
    AttackActionData |
    CaptureActionData |
    AwardCardsActionData |
    GameOverActionData |
    TransferUnitsActionData |
    TurnInCardsActionData |
    EliminatePlayerActionData |
    FoggedAttackActionData |
    FoggedTransferUnitsActionData |
    AssignAssassinTargetActionData |
    CatanRollActionData |
    ChatActionData |
    BeginTurnActionData |
    NudgeActionData |
    PlaceCapitalActionData |
    DisappearCountryActionData |
    BlizzardCountryActionData |
    HiddenAwardCardsActionData;

interface IAction {
    actionType: ActionType;
    id: number;
    userId: number;
    data?: ActionData;
    ts: string;
};

export interface NoneAction extends IAction {
    actionType: ActionType.None;
};
export interface CreateGameAction extends IAction {
    actionType: ActionType.CreateGame;
    data: CreateGameActionData;
}
export interface JoinGameAction extends IAction {
    actionType: ActionType.JoinGame;
}
export interface AssignSeatAction extends IAction {
    actionType: ActionType.AssignSeat;
    data: AssignSeatActionData;
}
export interface StartAction extends IAction {
    actionType: ActionType.Start;
}
export interface AssignCountryAction extends IAction {
    actionType: ActionType.AssignCountry;
    data: AssignCountryActionData;
}
export interface AwardUnitsAction extends IAction {
    actionType: ActionType.AwardUnits;
    data: AwardUnitsActionData;
}
export interface PlaceUnitsAction extends IAction {
    actionType: ActionType.PlaceUnits;
    data: PlaceUnitsActionData;
}
export interface AttackAction extends IAction {
    actionType: ActionType.Attack;
    data: AttackActionData;
}
export interface CaptureAction extends IAction {
    actionType: ActionType.Capture;
    data: CaptureActionData;
}
export interface AwardCardsAction extends IAction {
    actionType: ActionType.AwardCards;
    data: AwardCardsActionData;
}
export interface GameOverAction extends IAction {
    actionType: ActionType.GameOver;
    data: GameOverActionData;
}
export interface TransferUnitsAction extends IAction {
    actionType: ActionType.TransferUnits;
    data: TransferUnitsActionData;
}
export interface TurnInCardsAction extends IAction {
    actionType: ActionType.TurnInCards;
    data: TurnInCardsActionData;
}
export interface EliminatePlayerAction extends IAction {
    actionType: ActionType.EliminatePlayer;
    data: EliminatePlayerActionData;
}
export interface InvitedGameAction extends IAction {
    actionType: ActionType.InvitedGame;
}
export interface CancelGameAction extends IAction {
    actionType: ActionType.CancelGame;
}
export interface SurrenderAction extends IAction {
    actionType: ActionType.Surrender;
}
export interface FoggedAction extends IAction {
    actionType: ActionType.Fogged;
}
export interface FoggedAttackAction extends IAction {
    actionType: ActionType.FoggedAttack;
    data: FoggedAttackActionData;
}
export interface FoggedTransferUnitsAction extends IAction {
    actionType: ActionType.FoggedTransferUnits;
    data: FoggedTransferUnitsActionData;
}
export interface AssignAssassinTargetAction extends IAction {
    actionType: ActionType.AssignAssassinTarget;
    data: AssignAssassinTargetActionData;
}
export interface AddBotAction extends IAction {
    actionType: ActionType.AddBot;
}
export interface CatanRollAction extends IAction {
    actionType: ActionType.CatanRoll;
    data: CatanRollActionData;
}
export interface ChatAction extends IAction {
    actionType: ActionType.Chat;
    data: ChatActionData;
}
export interface BeginTurnAction extends IAction {
    actionType: ActionType.BeginTurn;
    data: BeginTurnActionData;
}
export interface NudgeAction extends IAction {
    actionType: ActionType.Nudge;
    data: NudgeActionData;
}
export interface PlaceCapitalAction extends IAction {
    actionType: ActionType.PlaceCapital;
    data: PlaceCapitalActionData;
}
export interface DisappearCountryAction extends IAction {
    actionType: ActionType.DisappearCountry;
    data: DisappearCountryActionData;
}
export interface BlizzardCountryAction extends IAction {
    actionType: ActionType.BlizzardCountry;
    data: BlizzardCountryActionData;
}

export interface HiddenAwardCardsAction extends IAction {
    actionType: ActionType.HiddenAwardCards;
    data: HiddenAwardCardsActionData;
}

export interface HiddenAction extends IAction {
    actionType: ActionType.Hidden;
}

export interface AcceptInviteAction extends IAction {
    actionType: ActionType.AcceptInvite;
}

export interface InviteEmailAction extends IAction {
    actionType: ActionType.InviteEmail;
}

export const GlobalActionTypes = [
    ActionType.CreateGame,
    ActionType.JoinGame,
    ActionType.AssignSeat,
    ActionType.Start,
    ActionType.GameOver,
    ActionType.InvitedGame,
    ActionType.EliminatePlayer,
    ActionType.CancelGame,
    ActionType.Surrender,
    ActionType.Chat,
    ActionType.BeginTurn,
    ActionType.Nudge,
    ActionType.BlizzardCountry,
    ActionType.AcceptInvite,
    ActionType.InviteEmail,
];

export const ActionTypesWithSeatData = {
    [ActionType.AssignSeat]: ['seatNumber'],
    [ActionType.AssignCountry]: ['seatNumber'],
    [ActionType.AssignAssassinTarget]: ['assassinSeatNumber', 'targetSeatNumber'],
    [ActionType.AwardUnits]: ['seatNumber'],
    [ActionType.PlaceUnits]: ['seatNumber'],
    [ActionType.Attack]: ['fromSeatNumber', 'toSeatNumber'],
    [ActionType.CatanRoll]: ['seatNumber'],
    [ActionType.FoggedAttack]: ['seatNumber'],
    [ActionType.FoggedTransferUnits]: ['seatNumber'],
    [ActionType.Capture]: ['seatNumber'],
    [ActionType.AwardCards]: ['seatNumber'],
    [ActionType.GameOver]: ['seatNumber'],
    [ActionType.TransferUnits]: ['seatNumber'],
    [ActionType.TurnInCards]: ['seatNumber'],
    [ActionType.EliminatePlayer]: ['seatNumber', 'deadSeatNumber'],
    [ActionType.Chat]: ['seatNumber'],
    [ActionType.BeginTurn]: ['seatNumber'],
    [ActionType.DisappearCountry]: ['seatNumber'],
    [ActionType.Nudge]: ['fromSeatNumber', 'toSeatNumber'],
    [ActionType.PlaceCapital]: ['seatNumber'],
    [ActionType.HiddenAwardCards]: ['seatNumber'],
    [ActionType.AcceptInvite]: ['seatNumber'],
};

export type BonusCalculation = {
    countryBonus: number;
    continentBonus: number;
    cardSetBonus: number;
    eliminationBonus: number;
    catanBonus?: number;
    capitalBonus?: number;
};

// ActionDatas
export type CreateGameActionData = {
    mapId: number;
};

export type AssignSeatActionData = {
    color: number;
    name: string;
    seatNumber: number;
    team?: number;
};

export type AssignCountryActionData = {
    countryId: number;
    seatNumber: number;
    units: number;
    extraData?: ApiCountryExtraData;
    revealCountries?: AssignCountryActionData[];
};

export type AwardUnitsActionData = {
    seatNumber: number;
    units: number;
    bonusCalculation: BonusCalculation;
};

export type PlaceUnitsActionData = {
    countryId: number;
    units: number;
    seatNumber: number;
};

export type AttackActionData = {
    fromCountryId: number;
    fromSeatNumber: number;
    fromUnits: number;
    fromUnitsTotal: number;
    toCountryId: number;
    toSeatNumber: number;
    toUnits: number;
    toUnitsTotal: number;
    result: CombatResult;
    revealCountries?: AssignCountryActionData[];
};

export type CaptureActionData = {
    countryId: number;
    seatNumber: number;
    revealCountries?: AssignCountryActionData[];
    fogCountries?: number[];
    bonusUnits?: number;
};

export type AwardCardsActionData = {
    seatNumber: number;
    cardIds: number[];
};

export type GameOverActionData = {
    seatNumber: number;
};

export type TransferUnitsActionData = {
    seatNumber: number;
    fromCountryId: number;
    toCountryId: number;
    units: number;
};

export type TurnInCardsActionData = {
    seatNumber: number;
    cardIds: number[];
    units: number;
};

export type EliminatePlayerActionData = {
    seatNumber: number;
    deadSeatNumber: number;
};

export type FoggedAttackActionData = {
    countryId: number;
    fromUnits: number;
    seatNumber: number;
    units: number;
};

export type FoggedTransferUnitsActionData = {
    countryId: number;
    units: number;
    seatNumber: number;
};

export type AssignAssassinTargetActionData = {
    assassinSeatNumber: number;
    targetSeatNumber: number;
}

export type CatanRollActionData = {
    seatNumber: number;
    rolls: number[];
};

export type ChatActionData = {
    seatNumber: number;
    channel: GameChatChannel;
    text: string;
};

export type BeginTurnActionData = {
    seatNumber: number,
    fogCountries?: number[];
};

export type NudgeActionData = {
    fromSeatNumber: number;
    toSeatNumber: number;
};

export type PlaceCapitalActionData = {
    seatNumber: number;
    countryId: number;
};

export type DisappearCountryActionData = {
    attackerSeatNumber: number;
    countryId: number;
    seatNumber: number;
    units: number;
    fogCountries?: number[];
};

export type BlizzardCountryActionData = {
    countryId: number;
};

export type HiddenAwardCardsActionData = {
    seatNumber: number;
    numCards: number;
}

export type InviteEmailActionData = {
    email: string;
};

import GameState from "../../../src/enum/gamestate";
import UserPlayerList from "../../user/playerlist";
import { GamePlaceUnitsState, GamePrivacy } from "../../../src/enum/game";
import { ApiSeat, ApiGame } from "../../../src/types/apigame";
import { getApproximateTimeString, getRelativeTimeString } from "../../../src/util/relativetime";
import { useSession } from "next-auth/react";
import pluralize from "../../../src/util/pluralize";

type Props = {
    game: ApiGame;
};

export default function GameGamesListTurn(
    {
        game,
    }: Props,
) {
    const { data: session } = useSession();
    const userId = Number(session.user['id']);

    const seats: ApiSeat[] = game.seats ?? [];
    let activeSeats: ApiSeat[] = game.activeSeats;

    let message = null;

    const wasCanceled = (() => {
        if (game.subState && game.subState.wasCanceled) {
            return true;
        }
        // older games didn't have the subState
        return activeSeats.every((seatObject) => seatObject.seatNumber === null);
    })();

    let isWaiting = true;
    let baseTime = game.turnStartTime ?? game.lastUpdated;

    const myInvite = game.invites?.find(
        (invite) => invite.user?.id === userId,
    );

    const isMyTurn = userId != null &&
        (myInvite || activeSeats.some((seatObject) => seatObject.userId === userId));

    if (game.state === GameState.Complete) {
        isWaiting = false;

        if (wasCanceled) {
            return <span>Canceled...</span>
        } else {
            message = ' won!';
        }
    } else if (game.state === GameState.Created) {
        if (myInvite) {
            message = ' to join';
            activeSeats = [
                {
                    user: myInvite,
                    userId: myInvite.user.id,
                    accepted: false,
                }
            ] as unknown as ApiSeat[];

            baseTime = myInvite.ts;
        } else if (
            game.privacy === GamePrivacy.AnyoneCanJoin
            && game.seats.length < game.maxSeats
        ) {
            const numPlayers = game.maxSeats - game.seats.length;
            const morePlayersText = pluralize(numPlayers, 'more player');

            message = <span><b>{morePlayersText}</b> to join</span>;
        } else if (seats.length <= 1) {
            // no invited players, activeSeat[0] is creator
            message = ' to invite players';
        } else if (activeSeats.length === 1 && activeSeats[0].userId === game.creatorId) {
            // waiting on the owner to do something
            // we know seats > 1
            message = ' to start';
        } else {
            // i need to join the game
            baseTime = game.created.valueOf();
            message = ' to join';
        }
    } else if (game.state === GameState.PlaceUnits && game.subState && game.subState.mode === GamePlaceUnitsState.BlindAtOnceUnits) {
        message = ' to place their initial units';
    } else {
        if (activeSeats.length === 1 && activeSeats[0].userId === userId) {
            message = ' to take your turn';
        } else {
            message = "'s turn"
        }
    }

    let waitingBlock = null;
    let messageBlock = null;
    if (isWaiting) {
        const approximateTime = getApproximateTimeString(baseTime, Date.now());
        const relativeTime = getRelativeTimeString(baseTime, Date.now());
        waitingBlock = <span title={relativeTime}>{`Waiting for `}</span>;
        messageBlock = <span title={relativeTime}>{message} for {approximateTime}</span>
    } else {
        messageBlock = <span>{message}</span>
    }
    const bodyElements = <>
        {waitingBlock}
        <UserPlayerList seats={activeSeats} />
        {messageBlock}
    </>;

    let body;
    if (isMyTurn && isWaiting) {
        body = <b>{bodyElements}</b>
    } else {
        body = bodyElements;
    }

    return <div>
        {body}
    </div>;
}

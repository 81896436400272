import { UserProfile } from "../../src/models/socket/profile";
import styles from './userprofiledialogcomponent.module.css';

type Props = {
    profile: UserProfile,
};

export default function UserProfileDialogStats(
    {
        profile,
    }: Props,
): JSX.Element {
    const stats = profile.stats;
    const gamesPlayed = stats?.gamesPlayed ?? 0;
    const gamesWon = stats?.gamesWon ?? 0;
    const unitsKilled = stats?.unitsKilled ?? 0;
    const unitsLost = stats?.unitsLost ?? 0;

    let gamesWonPercent: number;
    if (gamesPlayed > 0) {
        gamesWonPercent = Math.round(gamesWon * 100 / gamesPlayed);
    } else {
        gamesWonPercent = 0;
    }

    const formatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });
    const joinedDate = new Date(profile.user.created);
    const joinedDateString = formatter.format(joinedDate);

    return <div className={styles.statsTable}>
        <div className={styles.statsTableRow}>
            <div className={styles.statsTableRowName}>
                Joined:
            </div>
            <div className={styles.statsTableRowValue}>
                {joinedDateString}
            </div>
        </div>
        <div className={styles.statsTableRow}>
            <div className={styles.statsTableRowName}>
                Games Played:
            </div>
            <div className={styles.statsTableRowValue}>
                {gamesPlayed}
            </div>
        </div>
        <div className={styles.statsTableRow}>
            <div className={styles.statsTableRowName}>
                Games Won:
            </div>
            <div className={styles.statsTableRowValue}>
                {gamesWon} ({gamesWonPercent}%)
            </div>
        </div>
        <div className={styles.statsTableRow}>
            <div className={styles.statsTableRowName}>
                Units Killed:
            </div>
            <div className={styles.statsTableRowValue}>
                {unitsKilled}
            </div>
        </div>
        <div className={styles.statsTableRow}>
            <div className={styles.statsTableRowName}>
                Units Lost:
            </div>
            <div className={styles.statsTableRowValue}>
                {unitsLost}
            </div>
        </div>
    </div>
}

'use client';

import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSession } from 'next-auth/react';
import io, { Socket } from 'socket.io-client';
import { ClientToServer, ServerToClient } from '../../src/types/socket';
import Build from '../../build_info.json';
import { Spinner } from '@blueprintjs/core';

export enum SocketState {
    DISCONNECTED,
    CONNECTING,
    CONNECTED
};

// This class serializes emitWithAck() calls by storing their promise
// then when the next emitWithAck() is called, .then()s the previous
// promise and saves that new promise as the next promise to use.
class SerialSocket {
    private nextPromise: Promise<any> = null;
    constructor(private socket: Socket<ServerToClient, ClientToServer>) { }
    public async emitWithAck(event: string, ...args: any[]) {
        if (this.nextPromise) {
            this.nextPromise = this.nextPromise.then(
                () => this.socket.emitWithAck(event, ...args)
            );
        } else {
            this.nextPromise = this.socket.emitWithAck(event, ...args);
        }
        return this.nextPromise;
    }
}

type SocketContextProps = {
    socket: Socket<ServerToClient, ClientToServer>;
    getSerialSocket: (key?: string) => SerialSocket;
    state: SocketState;
};

const SocketContext = createContext<SocketContextProps>({
    socket: null,
    getSerialSocket: (key?: string) => null,
    state: SocketState.DISCONNECTED,
});

const RETRY_MS = 2000;

export function SocketProvider({ children }) {
    const session = useSession();
    const status = session.status;

    const [socket, setSocket] = useState<Socket<ServerToClient, ClientToServer>>(null);
    const [state, setState] = useState<SocketState>(SocketState.DISCONNECTED);
    const [versionReloading, setVersionReloading] = useState<boolean>(false);

    const serialMap: Map<string, SerialSocket> = useMemo(
        () => new Map<string, SerialSocket>(),
        [],
    );

    // This will return a unique object with emithWithAck, that will
    // not emit the event until all previous events have returned
    const getSerialSocket = useCallback(
        (key = '__default__') => {
            if (!socket) {
                return null;
            }
            if (!serialMap.has(key)) {
                serialMap.set(key, new SerialSocket(socket));
            }
            return serialMap.get(key);
        },
        [socket, serialMap],
    );

    useEffect(
        () => {
            // console.log('socket useEffect called with status', status);

            if (socket) {
                return;
            }

            // this is a sub-function only because it needs to be async
            const setupSocket = async () => {
                switch (status) {
                    case 'authenticated': {
                        // if we don't have a socket, we will now set it up
                        if (socket) {
                            return;
                        }

                        setState(SocketState.CONNECTING);

                        // in the future should render a token on page render to avoid this round trip

                        let response: Response;
                        try {
                            response = await fetch('/api/socket');
                        } catch (err) {
                            console.error('error connecting to websocket, error:', err.message);
                            setState(SocketState.DISCONNECTED);
                            return;
                        }

                        if (!response.ok) {
                            console.error('error connecting to websocket, server says:', await response.text());
                            setState(SocketState.DISCONNECTED);
                            return;
                        }
                        const json = await response.json();
                        const token = json.token;
                        const build = json.build;
                        if (build !== Build.git_sha1) {
                            console.log('client/server build mismatch, client:', Build.git_sha1, 'server:', build);
                            setVersionReloading(true);
                            const time = 1.5 * Math.random() + 1500; // between 1.5-3.0 seconds
                            setTimeout(() => location.reload(), time);
                            return;
                        }
                        const newSocket: Socket<ServerToClient, ClientToServer> = io(window.location.host, {
                            addTrailingSlash: false,
                            auth: { token }
                        });

                        newSocket.on('connect', () => {
                            console.log('connected');
                            setState(SocketState.CONNECTED);
                        });

                        newSocket.on('connect_error', (err) => {
                            console.error('error connecting to the server', err);
                            setState(SocketState.CONNECTING);

                            setTimeout(
                                () => newSocket.connect(),
                                RETRY_MS,
                            );

                            console.log('trying to reconnect in two second');
                        });

                        newSocket.on('disconnect', async (reason) => {
                            console.log(`disconnected because ${reason}`);
                            switch (reason) {
                                case 'io client disconnect':
                                    console.log('the client left');
                                    setState(SocketState.DISCONNECTED);
                                    break;
                                case 'io server disconnect':
                                    console.log('the server booted us');
                                    setState(SocketState.CONNECTING);

                                    setTimeout(
                                        () => newSocket.connect(),
                                        RETRY_MS,
                                    );

                                    console.log('trying to reconnect in two second');
                                    break;
                                case 'parse error':
                                case 'transport close':
                                case 'transport error':
                                case 'ping timeout':
                                    // client will try to automatically reconnect after
                                    // a random delay
                                    setState(SocketState.CONNECTING);
                                    break;
                            }
                        });

                        setSocket(newSocket);

                        if (typeof window !== 'undefined') {
                            window['spqr'] = {
                                ...window['spqr'],
                                socket: newSocket,
                            };
                        }

                        break;
                    }
                    case 'unauthenticated': {
                        // if we have a socket, we will now close it since as of 2023-5-29 we only use websocket while authenticated
                        setState(SocketState.DISCONNECTED);

                        if (socket) {
                            socket.close();
                            setSocket(null);
                        }

                        break;
                    }
                    case 'loading':
                        break;
                    default:
                        // not possible as of 2023-5-29
                        console.log('socket code was given unknown session status, should handle this', status);
                        break;
                }
            };

            setupSocket();
        },
        [socket, status],
    );

    if (versionReloading) {
        return <div style={{ textAlign: 'center' }}>
            <h1>Server updated, reloading...</h1>
            <Spinner />
        </div>
    }
    const value: SocketContextProps = {
        socket,
        getSerialSocket,
        state,
    };

    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    );
}

export function useSocket() {
    return useContext(SocketContext);
}

import { useSession } from 'next-auth/react';
import GameState from '../../src/enum/gamestate';
import { GameSocketMessageEventTypes, GameSockeNotifytMessageTypes, IGameSocketNotifyMessage } from '../../src/models/game/socket';
import { ApiGame } from '../../src/types/apigame';
import { usePageFocus } from '../../components/global/pagefocus';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSocket } from './socket';
import ActiveGamesClient from '../../src/util/activegames';
import { GameStateChanges } from '../../src/types/socket';

export type ActiveGamesProviderValue = {
    games: ApiGame[];
}

const ActiveGamesProviderContext = createContext<ActiveGamesProviderValue>({
    games: null,
});

export function ActiveGamesProvider({ children }) {
    const isPageFocused = usePageFocus();
    const { socket } = useSocket();
    const [games, setGames] = useState<ApiGame[] | null>(null);

    const { data: session, status } = useSession();
    const userId = status === 'authenticated' ? session.user.id : null;

    const client = useMemo(
        (): ActiveGamesClient => new ActiveGamesClient(socket, userId),
        [socket, userId],
    );

    const initGames = useCallback(
        async (): Promise<void> => {
            if (!isPageFocused) {
                return;
            }

            const newGames = await client.getActiveGames();
            if (!newGames) {
                return;
            }

            setGames(newGames);
        },
        [client, setGames, isPageFocused],
    );

    const updateGame = useCallback(
        async (gameId: number): Promise<void> => {
            const newGame = await client.getGame(gameId);
            if (!newGame) {
                return;
            }

            setGames(
                (oldGames) => {
                    const newGames = [...oldGames];

                    const index = newGames.findIndex(
                        (game) => game.id === newGame.id,
                    );

                    if (index >= 0) {
                        newGames[index] = newGame;
                    } else {
                        newGames.push(newGame);
                    }

                    return newGames.filter(
                        (game) => game.state !== GameState.Complete,
                    );
                },
            );
        },
        [client, setGames],
    );

    useEffect(
        () => {
            if (!socket) {
                return;
            }

            initGames();

            socket.on('connect', initGames);
            return () => {
                socket.off('connect', initGames);
            }
        },
        [socket, initGames],
    );

    useEffect(
        () => {
            if (!socket) {
                return;
            }

            const onNotifyGameChanged = (data: GameStateChanges) => {
                updateGame(data.gameId);
            };

            const onNotify = (message: IGameSocketNotifyMessage) => {
                switch (message.type) {
                    case GameSockeNotifytMessageTypes.Invited:
                    case GameSockeNotifytMessageTypes.Turn:
                    case GameSockeNotifytMessageTypes.DeclinedInvite:
                    case GameSockeNotifytMessageTypes.GameStarted:
                    case GameSockeNotifytMessageTypes.Joined:
                    case GameSockeNotifytMessageTypes.TurnEnded:
                    case GameSockeNotifytMessageTypes.NotifyGameChanged: {
                        const gameId = message.gameId;
                        updateGame(gameId);

                        break;
                    }
                }
            };

            socket.on(GameSocketMessageEventTypes.Notify, onNotify);
            socket.on('notifyGameChanged', onNotifyGameChanged);

            return () => {
                socket.off(GameSocketMessageEventTypes.Notify, onNotify);
                socket.off('notifyGameChanged', onNotifyGameChanged);
            };
        },
        [socket, updateGame],
    );

    const value = useMemo(
        (): ActiveGamesProviderValue => ({ games }),
        [games],
    );

    return (
        <ActiveGamesProviderContext.Provider value={value}>
            {children}
        </ActiveGamesProviderContext.Provider>
    );
}

export function useActiveGamesProvider() {
    return useContext(ActiveGamesProviderContext);
}

import { GamePrivacy } from "../../enum/game";
import GameState from "../../enum/gamestate";
import { ApiGame } from "../../types/apigame";

export enum GetGamesForUserType {
    All = 0,
    Active,
    Completed,
    Open,
}

export const GET_GAMES_COMPLETED_PAGE_SIZE: number = 50;

export type GetGamesForUserParams = {
    searchType?: GetGamesForUserType;
    take?: number;
    cursor?: number;
    gameIds?: number[];
    state?: GameState;
    privacy?: GamePrivacy;
}

type InviteSuccess = {
    status: 'ok';
    game: ApiGame;
}

type InviteError = {
    status: 'error';
    message: string;
};

export type InviteResult = InviteSuccess | InviteError;

type GetRandomGameNameSuccess = {
    status: 'ok';
    name: string;
}

type GetRandomGameNameError = {
    status: 'error';
    message: string;
};

export type GetRandomGameNameResult = GetRandomGameNameSuccess | GetRandomGameNameError;

type SetSlackIdSuccess = {
    status: 'ok',
};

type SetSlackIdError = {
    status: 'error';
    message: string;
};

export type SetSlackIdResponse = SetSlackIdSuccess | SetSlackIdError;

export type CheckSlackIdResponse = {
    status: 'ok' | 'notok';
};
